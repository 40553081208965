import 'normalize.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { components, formatters } from 'novapay-ui';
import Components from './components';

Vue.config.productionTip = false;

Vue.use(components);
Vue.use(Components);
Object.keys(formatters).forEach((name) => Vue.filter(name, formatters[name]));

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
