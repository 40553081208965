import { core } from 'novapay-ui';
const types = core.createTypes('main_page');

export default {
	[types.GET_OPERATIONS_SUCCESS]: (state, data) => {
		const { rows, total } = data;
		state.props = { ...state.props, operations: rows, total };
	},
	[types.GET_TRANSACTIONS_SUCCESS]: (state, data) => {
		const { rows, total } = data;
		state.props = { ...state.props, transactions: rows, total };
	},
	[types.SET_UPLOAD_PROGRESS]: (state, uploadProgress) => {
		state.props = { ...state.props, uploadProgress };
	},
	[types.UPLOAD_FILE_ERROR]: (state, uploadFileError) => {
		state.props = { ...state.props, uploadFileError };
	},
	[types.CLEAR_UPLOAD_FILE_ERROR]: (state) => {
		state.props = { ...state.props, uploadFileError: null };
	},
	[types.APPLY_QUERY]: (state, query) => {
		state.props = { ...state.props, query };
	},
	[types.CHANGE_TAB]: (state, table) => {
		state.props = { ...state.props, query: null, table };
	}
};
