import { core } from 'novapay-ui';
import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('root');

export default core.createStore(actions, {}, mutations, {
	key: 'root',
	initial: 'idle',
	states: {
		idle: {
			on: {
				[types.PAGE_CHANGE]: 'loading',
				[types.ERROR]: 'error'
			}
		},
		loading: {
			entry: 'getAppData',
			on: {
				[types.APP_DATA_SUCCESS]: 'render',
				[types.APP_DATA_ERROR]: 'login',
				[types.ERROR]: 'error'
			}
		},
		login: {
			on: {
				[types.PAGE_CHANGE]: 'loading',
				[types.LOGIN_SUCCESS]: 'loading',
				[types.ERROR]: 'error'
			}
		},
		render: {
			on: {
				[types.LOGOUT]: 'login',
				[types.APP_DATA_ERROR]: 'login',
				[types.PAGE_CHANGE]: 'loading',
				[types.ERROR]: 'error'
			}
		},
		error: {
			on: {
				[types.PAGE_CHANGE]: 'loading'
			}
		}
	}
});
