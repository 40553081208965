import { core } from 'novapay-ui';
import { v4 as uuid } from 'uuid';

const types = core.createTypes('root');

export default {
	[types.APP_DATA_SUCCESS]: (state, data) => {
		const { contragent, lists } = data;
		state.props = { ...state.props, contragent, lists };
	},
	[types.PAGE_CHANGE]: (state, needAuth) => {
		state.props = { needAuth };
	},
	[types.UPDATE_CONTRAGENT_BALANCE]: (state, balance) => {
		state.props = { ...state.props, contragent: { ...state.props.contragent, balance } };
	},
	[types.LOGIN_SUCCESS]: (state, contragent) => {
		state.props = { contragent };
	},
	[types.FORM_VALIDATION_ERRORS]: (state, loginValidationErrors) => {
		state.props = { ...state.props, loginValidationErrors };
	},
	[types.LOGOUT]: (state) => {
		state.props = {};
	},
	[types.ERROR]: (state, props) => {
		const { lists } = state.props;
		state.props = {
			lists,
			code: (props && props.code) || '500',
			uuid: (props && props.uuid) || null
		};
	},
	[types.ERROR_ALERT]: (state, error) => {
		state.props = {
			...state.props,
			snackbars: (state.props.snackbars || []).concat([{ id: uuid(), variant: 'error', title: error.text }])
		};
	},
	[types.ADD_SNACKBAR]: (state, snackbar) => {
		state.props = {
			...state.props,
			snackbars: (state.props.snackbars || []).concat([{ id: uuid(), ...snackbar }])
		};
	},
	[types.REMOVE_SNACKBAR]: (state, id) => {
		state.props = {
			...state.props,
			snackbars: state.props.snackbars?.filter((s) => s.id !== id)
		};
	}
};
