import Vue from 'vue';
import Router from 'vue-router';
import Root from '@/modules/root';

import store from '@/store';

import { core } from 'novapay-ui';
import qs from 'qs';

const rootTypes = core.getTypes('root').namespaced;
const errorRoutes = ['/404', '/500', '/403'];

Vue.use(Router);

const routes = [
	{
		path: '/',
		component: Root,
		children: [
			{ path: '/', component: () => import(/* webpackChunkName: "main-page" */ '@/modules/main-page') },
			{
				path: '/password',
				component: () => import(/* webpackChunkName: "change-password" */ '@/modules/change-password')
			},
			{
				path: '*',
				component: () => import(/* webpackChunkName: "errors" */ '@/components/errors'),
				props: (route) => ({ code: errorRoutes.includes(route.path) ? route.path.replace('/', '') : '404' })
			}
		]
	}
];

const router = new Router({
	parseQuery(query) {
		return qs.parse(query, { arrayLimit: Infinity, parameterLimit: Infinity });
	},
	stringifyQuery(query) {
		let result = qs.stringify(query);
		return result ? '?' + result : '';
	},
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach(function (to, from, next) {
	let needAuth = true;
	if (to.path === '/password') {
		needAuth = false;
	}
	store.commit(rootTypes.PAGE_CHANGE, needAuth, { root: true });
	next();
});

export default router;
