<template>
	<div class="login-page">
		<div class="login-screen">
			<form @submit.prevent="submit">
				<i class="icon-logo" />
				<div class="greeting">
					<span class="title">Вітаємо!</span>
					<span class="text">Ласкаво просимо до кабінету NovaPay Комфортний</span>
				</div>
				<NP-Input v-model="loginForm.identifier" label="ЕДРПОУ" type="number" :error="formErrors.identifier" />
				<NP-Input v-model="loginForm.password" label="Пароль" type="password" :error="formErrors.password" />
				<NP-Button type="submit" priority="high" :loading="loadingActions.includes('login')"> Увійти </NP-Button>
				<NP-Tooltip class="tooltip" text="Посилання на зміну пароля буде надіслано на вашу електронну пошту">
					<div class="help">
						<router-link class="router-link" to="/password">Забули пароль</router-link>
						<i class="icon-help" />
					</div>
				</NP-Tooltip>
			</form>
		</div>
		<div class="login-image" />
	</div>
</template>

<script>
import { validation } from 'novapay-ui';
export default {
	name: 'Login',
	props: {
		state_key: { type: [Object, String], default: null },
		loadingActions: { type: Array, default: () => [] },
		errors: { type: Array, default: () => [] },
		login: { type: Function, default: null }
	},
	data() {
		return {
			loginForm: {
				identifier: null,
				password: null
			}
		};
	},
	computed: {
		formErrors() {
			return validation.computeValidationMessages(this.errors, ['identifier', 'password']);
		}
	},
	methods: {
		submit() {
			this.login({ ...this.loginForm, loadingAction: true });
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-help: '~@assets/images/icon-help.svg';
$icon-logo: '~@assets/images/logo.svg';
$bg-image: '~@assets/images/bg-image.png';

.login-page {
	display: flex;
	height: 100%;
	width: 100%;
	.login-image {
		height: 100%;
		width: 70%;
		background: url($bg-image) 0 / 100% 100% no-repeat;
	}
	.login-screen {
		position: relative;
		height: 100%;
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $white;
		.icon-logo {
			position: absolute;
			top: 32px;
			left: 40px;
			height: 40px;
			@include make-icon($icon-logo, #e01417, 200px, 40px);
		}
		.greeting {
			margin-bottom: 32px;
			font-style: normal;
			font-style: normal;
			font-size: 16px;
			.title {
				display: block;
				font-family: Proxima Nova Semibold;
				font-size: 24px;
				margin-bottom: 4px;
			}
			.text {
				display: block;
				color: $gray-40;
				max-width: 300px;
			}
		}
		.input {
			margin-bottom: 16px;
			width: 281px;
		}
		.button {
			width: 281px;
		}
		::v-deep .tooltip.tooltip-container {
			display: block;
			position: relative;
			margin-top: 24px;
			color: $gray-40;
			.help {
				display: flex;
				align-items: center;
				.router-link {
					font-size: 14px;
					color: $gray-50;
					text-decoration: none;
					margin-right: 7px;
					&:hover {
						cursor: pointer;
						color: $black;
					}
				}
				.icon-help {
					@include make-icon($icon-help, $red, 16px, 16px);
				}
			}
			.tooltip {
				position: absolute;
				top: 25px;
				left: 30px;
				font-size: 14px;
				line-height: 113.1%;
				max-width: 200px;
				background: $white;
				color: $gray-40;
				box-shadow: 0px 0px 10px rgba(96, 108, 156, 0.4);
				white-space: normal;
				text-align: center;
			}
		}
	}
}
</style>
