import { core } from 'novapay-ui';
import actions from './actions';
import mutations from './mutations';

const types = core.createTypes('change_password');

export default core.createStore(actions, {}, mutations, {
	key: 'change_password',
	initial: 'idle',
	states: {
		idle: {
			on: {
				[types.GO_TO_SEND_URL_FORM]: 'send_url_form',
				[types.GO_TO_CHANGE_PASSWORD_FORM]: 'change_password_form'
			}
		},
		send_url_form: {
			on: {
				[types.SEND_URL_SUCCESS]: 'idle'
			}
		},
		change_password_form: {
			on: {
				[types.CHANGE_PASSWORD_SUCCESS]: 'idle'
			},
			initial: 'loading',
			states: {
				loading: {
					entry: 'checkTokenApplicability',
					on: {
						[types.CHECK_TOKEN_SUCCESS]: 'main'
					}
				},
				main: {}
			}
		}
	}
});
