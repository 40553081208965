<template>
	<div class="app-root">
		<div v-if="state_key === 'render'" class="content-screen">
			<Menu v-if="props.contragent" :logout="logout" :loading-actions="props.loadingActions" />
			<RouterView />
		</div>
		<Login
			v-if="state_key === 'login'"
			:state_key="state_key"
			:login="login"
			:loading-actions="props.loadingActions"
			:errors="props.loginValidationErrors"
		/>
		<NP-Snackbars :data="props.snackbars" :close="closeSnackbar" />
		<Errors v-if="state_key === 'error'" :code="props.code" :uuid="props.uuid" />

		<MainPageSplashScreen v-if="state_key === 'loading'" />
	</div>
</template>

<script>
import { core } from 'novapay-ui';
import actions from './store/actions';

import Login from './components/login.vue';
import Menu from './components/menu.vue';
import MainPageSplashScreen from './components/splash.vue';

export default core.createComponent({
	name: 'root',
	components: { Login, Menu, MainPageSplashScreen },
	actions
});
</script>
