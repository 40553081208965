import actions from './actions';
import mutations from './mutations';
import { core } from 'novapay-ui';

const types = core.createTypes('main_page');

export default core.createStore(actions, {}, mutations, {
	key: 'main_page',
	initial: 'idle',
	states: {
		idle: {
			on: {
				[types.CHANGE_TAB]: 'change_tab'
			}
		},
		change_tab: {
			entry: 'setActiveTab',
			on: {
				[types.SET_OPERATIONS_TABLE]: 'operations_table',
				[types.SET_TRANSACTIONS_TABLE]: 'transactions_table'
			}
		},
		operations_table: {
			on: {
				[types.CHANGE_TAB]: 'change_tab'
			},
			initial: 'render',
			states: {
				render: {
					on: {
						[types.APPLY_QUERY]: 'loading'
					}
				},
				loading: {
					entry: 'getOperations',
					on: {
						[types.GET_OPERATIONS_SUCCESS]: 'render'
					}
				}
			}
		},
		transactions_table: {
			initial: 'render',
			on: {
				[types.CHANGE_TAB]: 'change_tab'
			},
			states: {
				render: {
					on: {
						[types.APPLY_QUERY]: 'loading'
					}
				},
				loading: {
					entry: 'getTransactions',
					on: {
						[types.GET_TRANSACTIONS_SUCCESS]: 'render'
					}
				}
			}
		}
	}
});
