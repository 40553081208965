import Vue from 'vue';
import Vuex from 'vuex';

import { core } from 'novapay-ui';
import root from '@/modules/root/store';
import main_page from '@/modules/main-page/store';
import change_password from '@/modules/change-password/store';

Vue.use(Vuex);

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	modules: { root, main_page, change_password },
	plugins: [core.statePlugin]
});
