import { core } from 'novapay-ui';
const types = core.createTypes('change_password');

export default {
	[types.GO_TO_SEND_URL_FORM]: (state) => {
		state.props = { ...state.props, loginValidationErrors: null };
	},
	[types.CHECK_TOKEN_SUCCESS]: (state, changePasswordData) => {
		state.props = { ...state.props, changePasswordData };
	},
	[types.FORM_VALIDATION_ERRORS]: (state, validationErrors) => {
		state.props = { ...state.props, validationErrors };
	}
};
