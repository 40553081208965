<template>
	<NP-SplashScreen>
		<div class="form">
			<div class="container">
				<div class="item" />
				<div class="item" />
			</div>
			<div class="table" />
		</div>
	</NP-SplashScreen>
</template>

<script>
export default {
	name: 'MainPageSplashScreen'
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';

::v-deep .controls-placeholder {
	display: none;
}
::v-deep .menu-placeholder {
	display: none;
}
.form {
	display: flex;
	padding: 70px 232px 0 232px;
	.container {
		display: flex;
		flex-direction: column;
		margin-right: 40px;
		.item {
			position: relative;
			width: 309px;
			height: 232px;
			background: $gray-10;
			margin-top: 24px;
			border-radius: 6px;
			&:first-child {
				&::before {
					content: '';
					position: absolute;
					top: 42px;
					left: 24px;
					width: 50px;
					height: 50px;
					background: $gray-20;
					border-radius: 50px;
				}
				&::after {
					content: '';
					position: absolute;
					top: 52px;
					left: 92px;
					width: 190px;
					height: 30px;
					background: $gray-20;
					border-radius: 50px;
				}
			}
			&:last-child {
				&::before {
					content: '';
					position: absolute;
					top: 42px;
					left: 24px;
					width: 260px;
					height: 100px;
					background: $gray-20;
					border-radius: 30px;
				}
				&::after {
					content: '';
					position: absolute;
					top: 170px;
					left: 24px;
					width: 260px;
					height: 30px;
					background: $gray-20;
					border-radius: 50px;
				}
			}
		}
	}
	.table {
		margin-top: 24px;
		flex-basis: 70%;
		height: 700px;
		background: $gray-10;
		border-radius: 6px;
	}
}
</style>
