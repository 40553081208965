<template>
	<div>
		<div class="menu">
			<div class="logo">
				<i class="icon-logo" />
				<span>Цифровий кабінет</span>
			</div>
			<NP-Button
				type="button"
				class="logout"
				@click="() => logout({ loadingAction: true })"
				:loading="loadingActions.includes('logout')"
			>
				<div class="logout-icon" slot="icon" />
				Вийти
			</NP-Button>
		</div>
		<div class="curves" />
	</div>
</template>

<script>
export default {
	name: 'Menu',
	props: {
		logout: { type: Function, default: null },
		loadingActions: { type: Array, default: () => [] }
	}
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables';
@import '@assets/scss/mixins';

$icon-logo: '~@assets/images/logo.svg';
$icon-logout: '~@assets/images/icon-logout.svg';
$icon-vertical-line: '~@assets/images/icon-vertical-line.svg';
$curve-1: '~@assets/images/curve-1.svg';
$curve-2: '~@assets/images/curve-2.svg';
$logo-color: #e01417;

.menu {
	display: flex;
	justify-content: space-between;
	padding: 24px 232px;
	.logo {
		position: relative;
		display: flex;
		align-items: center;
		z-index: 1;
		width: fit-content;
		.icon-logo {
			@include make-icon($icon-logo, $logo-color, 87px, 25px);
		}
		span {
			font-family: Proxima Nova Semibold;
			color: $logo-color;
			font-size: 18px;
			line-height: 113.1%;
			font-style: normal;
			margin-left: 18px;
		}
		&::after {
			content: url($icon-vertical-line);
			color: $logo-color;
			position: absolute;
			left: 94px;
			top: 13px;
		}
	}
	.logout .logout-icon {
		@include make-icon($icon-logout, $black, 20px, 20px);
	}
}
.curves {
	&::after {
		content: url($curve-1);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
	}
	&::before {
		content: url($curve-2);
		position: absolute;
		top: 200px;
		right: 0;
		z-index: 0;
	}
}
@media (max-height: 790px) {
	.curves::before {
		top: 50px;
	}
}
@media (max-width: 1510px) {
	.menu {
		padding: 24px;
	}
}
</style>
