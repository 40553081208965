import { core, http, validation } from 'novapay-ui';
import Promise from 'bluebird';
import handleError from '@services/handle-api-error';
import router from '@/router';

const types = core.getTypes('root');

const validateLogin = validation.compile({
	properties: {
		identifier: { type: 'string', minLength: 1, maxLength: 255 },
		password: { type: 'string', minLength: 1, maxLength: 255 }
	}
});

const getAppData = async (context) => {
	let { needAuth = true } = context.state.props || {};
	let [contragent, lists] = await Promise.all([needAuth && http('/v3/session'), http('/v3/lists')]);

	if ((needAuth && !handleError()(contragent, context)) || !handleError()(lists, context)) {
		return;
	}

	return context.commit(types.APP_DATA_SUCCESS, {
		contragent: (needAuth && contragent.data) || null,
		lists: lists.data
	});
};

const login = async (context, payload) => {
	let { data, errors } = validateLogin(payload);
	if (errors) {
		return context.commit(types.FORM_VALIDATION_ERRORS, errors);
	}
	let res = await http('/v3/session', { method: 'PUT', data });
	if (!handleError()(res, context)) {
		return;
	}
	return context.commit(types.LOGIN_SUCCESS, res.data);
};

const logout = async (context) => {
	let res = await http('/v3/session', { method: 'DELETE' });
	if (res.status !== 200) {
		return context.commit(types.ERROR);
	}
	router.push('/');
	return context.commit(types.LOGOUT);
};

const closeSnackbar = (context, id) => {
	return context.commit(types.REMOVE_SNACKBAR, id);
};

export default {
	login,
	logout,
	getAppData,
	closeSnackbar
};
