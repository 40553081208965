import { core, http } from 'novapay-ui';
import isEqual from 'lodash/isEqual';
import router from '@/router';
import handleError from '@services/handle-api-error';

const types = core.getTypes('change_password');
const rootTypes = core.getTypes('root');

const defineState = (context) => {
	let { query } = router.currentRoute;
	if (query?.token) {
		return context.commit(types.GO_TO_CHANGE_PASSWORD_FORM);
	}
	return context.commit(types.GO_TO_SEND_URL_FORM);
};

const checkTokenApplicability = async (context) => {
	let { token } = router.currentRoute.query;
	let res = await http(`/v3/session/password?token=${token}`);
	let handler = () => {
		router.push('/', () =>
			context.commit(
				rootTypes.namespaced.ADD_SNACKBAR,
				{ title: res.data.error || 'Виникла помилка, спробуйте ще раз', variant: 'error' },
				{ root: true }
			)
		);
	};
	if (!handleError(handler)(res, context)) {
		return;
	}
	let { identifier, name } = res.data;
	return context.commit(types.CHECK_TOKEN_SUCCESS, { identifier, name });
};

const sendUrlForPasswordChange = async (context, payload) => {
	let res = await http('/v3/session/change-password', {
		method: 'POST',
		data: { identifier: payload.identifier }
	});
	if (!handleError()(res, context)) {
		return;
	}
	context.commit(
		rootTypes.namespaced.ADD_SNACKBAR,
		{ title: `Посилання надіслано на електронну пошту ${res.data.mask}`, variant: 'success' },
		{ root: true }
	);
};

const changePassword = async (context, payload) => {
	let { password, passwordSubmit } = payload;
	let { token } = router.currentRoute.query;
	if (!password) {
		let err = [{ dataPath: '.new_password', message: 'Введіть новий пароль' }];
		return context.commit(types.FORM_VALIDATION_ERRORS, err);
	}
	if (!passwordSubmit) {
		let err = [{ dataPath: '.submit', message: 'Введіть підтвердження паролю' }];
		return context.commit(types.FORM_VALIDATION_ERRORS, err);
	}
	if (!isEqual(password, passwordSubmit)) {
		let err = [{ dataPath: '.new_password', message: 'Паролі не співпадають' }];
		return context.commit(types.FORM_VALIDATION_ERRORS, err);
	}
	let res = await http('/v3/session/password', { method: 'POST', data: { password, token } });
	if (!handleError()(res, context, 200, types.FORM_VALIDATION_ERRORS)) {
		return;
	}
	router.push('/', () => {
		context.commit(
			rootTypes.namespaced.ADD_SNACKBAR,
			{ title: 'Пароль успішно змінено', variant: 'success' },
			{ root: true }
		);
	});
};

export default {
	checkTokenApplicability,
	sendUrlForPasswordChange,
	changePassword,
	defineState
};
